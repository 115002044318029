<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png" alt />
        <span class="center">项目管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">项目列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">项目详情</span>
      </div>
    </div>
    <el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>基础字段</span>
          </div>
        </div>

        <el-descriptions class="margin-top" style="margin-top: 20px" :column="3" :size="size">
          <el-descriptions-item label="项目标号">
            {{
            detailInfo.code
            }}
          </el-descriptions-item>
          <el-descriptions-item label="超级管理员账号">
            <span v-if="detailInfo.super_user">
              {{
              detailInfo.super_user.phone
              }}
            </span>
          </el-descriptions-item>

          <el-descriptions-item label="企业名称">
            {{
            detailInfo.name
            }}
          </el-descriptions-item>
          <el-descriptions-item label="企业统一信用编码">
            {{
            detailInfo.idCardNumber
            }}
          </el-descriptions-item>
          <el-descriptions-item label="企业logo">
            <div style="display: flex; align-items: center">
              <el-tooltip class="item" effect="dark" content="点击查看图片" placement="top-start">
                <i
                  style="font-size: 26px"
                  class="el-icon-picture-outline font-img"
                  @click="showImg(detailInfo.config.styleLogo, '查看照片')"
                ></i>
              </el-tooltip>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="联系人">
            {{
            detailInfo.config.username
            }}
          </el-descriptions-item>
          <el-descriptions-item label="联系方式">
            {{
            detailInfo.config.userPhone
            }}
          </el-descriptions-item>
          <el-descriptions-item label="接受通知手机号">
            {{
            detailInfo.config.smsPhone
            }}
          </el-descriptions-item>
          <el-descriptions-item label="接受通知邮箱">
            {{
            detailInfo.config.email
            }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>微信配置</span>
          </div>
        </div>
        <!-- <el-descriptions class="margin-top" style="margin-top:20px" :column="3" :size="size"> -->
        <el-descriptions class="margin-top" style="margin-top: " :column="3" :size="size">
          <el-descriptions-item label="微信appid">
            {{
            detailInfo.config.wx_appid
            }}
          </el-descriptions-item>
          <el-descriptions-item label="微信秘钥">
            {{
            detailInfo.config.wx_secret
            }}
          </el-descriptions-item>
          <el-descriptions-item label="微信服务器认证文件名称">
            {{
            detailInfo.config.wx_serverfile
            }}
          </el-descriptions-item>
          <el-descriptions-item label="微信服务器认证文件内容">
            {{
            detailInfo.config.wx_serverfileContent
            }}
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>页面个性化配置</span>
          </div>
        </div>
        <el-descriptions class="margin-top" style="margin-top: 20px" :column="3" :size="size">
          <el-descriptions-item label="登录页背景图">
            <el-tooltip class="item" effect="dark" content="点击查看图片" placement="top-start">
              <i
                class="el-icon-picture-outline font-img"
                style="font-size: 26px"
                @click="showImg(detailInfo.config.styleBackImg, '查看照片')"
              ></i>
            </el-tooltip>
          </el-descriptions-item>
        </el-descriptions>
      </el-card>
      <el-card class="box-card">
        <div class="left_top">
          <div class="left_icon">
            <span></span>
            <span>计费信息</span>
          </div>
        </div>
        <div class="text item">
          <el-descriptions class="margin-top" style="margin-top: 20px" :column="3" :size="size">
            <el-descriptions-item label="购买的短信数">
              {{
              detailInfo.config.sms_buy_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余短信数">
              {{
              detailInfo.config.sms_surplus_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="购买的合同数">
              {{
              detailInfo.config.contract_buy_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余合同数">
              {{
              detailInfo.config.contract_surplus_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="购买的认证数">
              {{
              detailInfo.config.auth_buy_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余认证数">
              {{
              detailInfo.config.auth_surplus_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="购买的发放笔数">
              {{
              detailInfo.config.pay_buy_num
              }}
            </el-descriptions-item>
            <el-descriptions-item label="剩余发放笔数">
              {{
              detailInfo.config.pay_surplus_num
              }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </el-card>
    </el-card>
    <el-dialog
      class="showImgDialog"
      width="680px"
      :center="true"
      :title="dialogTitle"
      :visible.sync="showImgVisible"
      :append-to-body="true"
    >
      <div class="imgBox">
        <img style="width: 600px;" :src="dialogImgUrl" class="img-resource" alt />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
export const customerInfo = api()('enterprise.info.json');
export default {
  data () {
    return {
      code: '',
      detailInfo: {

      },
      dialogTitle: '',
      showImgVisible: false,
      passShow: false,
      dialogImgUrl: '',
      size: ''
    }
  },
  created () {
    this.code = this.$route.query.code;
    this.logoData()
  },
  methods: {
    showImg (img, title) {
      this.dialogImgUrl = img;
      this.dialogTitle = title;
      console.log(this.dialogImgUrl);
      this.showImgVisible = true;
    },
    logoData () {
      const params = {
        code: this.code
      };
      customerInfo(params).then((res) => {
        this.detailInfo = res;
        console.log(this.detailInfo)

      });
    }
  },
}
</script>
<style lang="scss">
.text-align {
  display: flex;
  text-align: left;
  line-height: 32px;
  padding-bottom: 8px;
}
</style>